import { template as template_21211f4686e54d1c8ccb72f37e453473 } from "@ember/template-compiler";
const WelcomeHeader = template_21211f4686e54d1c8ccb72f37e453473(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
