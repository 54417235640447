import { template as template_b9fc793f641946c7a753bf5517be0d81 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b9fc793f641946c7a753bf5517be0d81(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
