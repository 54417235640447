import { template as template_3f4c31c4466e4597a4a596e70da287ba } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_3f4c31c4466e4597a4a596e70da287ba(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
